import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import OneSignalReact from 'react-onesignal';

import { oneSignalAdminAppId } from 'services/prefix-holder';
import PageTitleContext from 'contexts/PageTitleContext';
import Routes from 'routes';
import { ErrorBoundary } from '@safelyq/base-feature';
import ScrollToTop from 'components/ScrollToTop';

import 'bulma.css';
import 'bootstrap.grid.css';

console.disableYellowBox = true;

console.warn = () => { };
console.error = () => { };


const App = () => {
  const [pageTitle, setPageTitle] = useState('');

  // Initialize OneSignal 
  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        console.log('Initializing Admin OneSignal...');
        await OneSignalReact.init({ appId: oneSignalAdminAppId });
        OneSignalReact.showSlidedownPrompt();

        console.log('Admin OneSignal initialized successfully.');
      } catch (error) {
        console.error('Error initializing OneSignal:', error);
      }
    };

    initializeOneSignal();
  }, []);

  return (
    <ErrorBoundary>
      <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
      </PageTitleContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
