import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';
import { Container, Column, Level, Button, Icon } from '@safelyq/bulma-ui-library';
import { useBusinessDispatch } from '@safelyq/manage-business';
import { BusinessImagesSetup } from '../BusinessImagesSection';
import { HoursOperation } from '../HoursOperation';
import { HolidaySchedule } from '../HolidaySchedule';
import { ServicesSetup } from '../ServicesSetup';
import { TrafficSetup } from '../TrafficSetup';
import { CheckInSetup } from '../CheckInSetup';
import { AppointmentConfirmationSetup } from '../AppointmentConfirmationSetup';
import { CommunicationTemplates } from '../CommunicationTemplates';
import { GeoLocationSetup } from '../GeoLocationSetup';
import { CommunicationChecksSetup } from '../CommunicationChecks';
import { BUSINESS_PROFILE_MESSAGES } from '../../common/constants';
import { toast as showToast } from 'react-toastify';
import { useData } from './hooks';
import CreateBusinessProfile from '../CreateBusinessProfile/CreateBusinessProfile';
import { newDummySlots } from '../HoursOperation/constants';
import { useSelector } from 'react-redux';

export const LocationSetup = ({ ...props }) => {
  const { business, countries, categories, organizations, refetch, isLoading, onSaveBusiness, businessHoursValidator, udpateBusinessHoursStatus, handleBusinessStatusChange, validationResult } = props || {};
  const reserveValidation = business?.services?.filter(service => service?.isGoogleReserve).length !== 0 ? true : false;
  const googleValidation = (business?.allowBusinessSchedule && business?.allowUserSchedule);

  const history = useHistory();
  const { id = 0 } = useParams();
  const { pathname } = useLocation();
  const { data } = useData();
  const [organization, setOrganization] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [addarr, setAddarr] = useState([0])

  // const businessCategoryName = useSelector(state => state?.business?.businessCategory?.name);

  const genericNumberMessagingAllowed = useSelector((state) => state?.business?.businessFeatures?.genericNumberMessagingAllowed);
  const stateBusinessId = useSelector(state => state?.business?.businessId);

  const dispatch = useBusinessDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_BUSINESS_DATA', payload: { business } });
    if (id) {
      setActiveStep(12);
      setOrganization(-1);
    }
  }, [business]);

  const handleOrganizationSwitch = ({ target: { value: id } }) =>
    setOrganization(id);

  const handleSubmit = (data, nextStep, taostMessages) => {
    let { id: idNew } = business || {}

    let businessId = id ? id : idNew ? idNew : 0

    if (businessId === 0 && nextStep !== 2) {

      businessId = stateBusinessId;
      // businessId = localStorage.getItem('businessId') || stateBusinessId;
    }

    const variables = {
      businessInput: {
        id: businessId,
        ...data,
        organizationId: parseInt(organization, 10),
      },
    };

    onSaveBusiness({ variables })
      .then(({ data }) => {
        const { saveBusiness } = data || {};

        if (saveBusiness.isSaved) {
          showToast.success(taostMessages?.success ?? BUSINESS_PROFILE_MESSAGES.Success);
        }
        else {
          showToast.error(saveBusiness?.errorMessage ?? taostMessages?.error ?? BUSINESS_PROFILE_MESSAGES.Error);
        }

        // localStorage.setItem('businessId', id);
        if (id === 0) {
          setActiveStep(nextStep);
        }

        if (organization !== -1) {
          setAddarr([nextStep])
        }
      })
      .catch(() => showToast.error(taostMessages?.error ?? BUSINESS_PROFILE_MESSAGES.Error));
  };

  const gotToNextStep = (nextStep) => {
    if (organization !== -1) { // it means if business is newly created
      setAddarr(nextStep)
    }
  }
  const handleAccordianChange = (sa) => {
    setAddarr(sa)
  }

  // if the setup is incomplete and business is also enabled than disable the business
  useEffect(() => {

    if (validationResult === true && business?.isActive) {
      handleBusinessStatusChange(false)
    }
  }, [validationResult])


  // Redirect to the subscription plans
  const handleUpgrade = () => {
    history.push(`/admin/pricing/${id}`);
  };

  return (
    <Container>
      {!id && (
        <Column size='12'>
          <Level>
            <Level.Left>
              <div className='control'>
                <label className='label'>Organization</label>
                <div className={`select is-fullwidth`}>
                  <select value={organization} onChange={handleOrganizationSwitch}  >
                    <option value={''} selected disabled> Select Organization </option>
                    {organizations.map(({ id, name }, index) => (<option key={`organization-${index}`} value={id}>  {name}  </option>))}
                  </select>
                </div>
              </div>
            </Level.Left>
            <Level.Right>
              <Link to='/admin/create-organization'>
                <Button color='info'>Create Organization</Button>
              </Link>
            </Level.Right>
          </Level>
        </Column>
      )}

      <Accordion allowZeroExpanded={true} preExpanded={!id ? [0] : String(pathname).includes('/promotions') ? [0] : ''}  >
        <AccordionItem uuid={0}>
          <AccordionItemHeading>
            <AccordionItemButton>{business?.name} Details
              {/* {!isGoogleReserve && */}
              {
                validationResult &&
                < span size='6' className='has-text-danger ml-4'>
                  <small>
                    <i>
                      Incomplete Setup!
                    </i>
                  </small>
                </span>
              }</AccordionItemButton>

          </AccordionItemHeading>
          <AccordionItemPanel>
            <Accordion allowZeroExpanded={true} onChange={handleAccordianChange}   >
              <AccordionItem uuid={1} dangerouslySetExpanded={addarr.includes(1)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 1 ? 1 : 0.5, pointerEvents: organization && activeStep >= 1 ? 'visible' : 'none', }} >
                  <AccordionItemButton>Profile</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel  >
                  <CreateBusinessProfile
                    refetchManageBusiness={refetch} toolTip={data?.profile} organization={organization} gotToNextStep={gotToNextStep} onChangeStep={(nextStep) => setActiveStep(nextStep)} />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={2} dangerouslySetExpanded={addarr.includes(2)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 2 ? 1 : 0.5, pointerEvents: organization && activeStep >= 2 ? 'visible' : 'none', }} >
                  <AccordionItemButton style={{ background: business?.pictures?.length === 0 ? '#ffecf0' : '', }}  >
                    Business Images
                    {business?.pictures?.length === 0 && (<span className='ml-3 has-text-danger'> <small>Add atleast one image</small>  </span>)}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <BusinessImagesSetup
                    data={{ ...business }}
                    refetch={refetch}
                    onChangeStep={(nextStep) => setActiveStep(nextStep)}
                    toolTip={data?.businessImage}
                    gotToNextStep={gotToNextStep}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={3} dangerouslySetExpanded={addarr.includes(3)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 3 ? 1 : 0.5, pointerEvents: organization && activeStep >= 3 ? 'visible' : 'none', }}    >
                  <AccordionItemButton style={{ background: businessHoursValidator[`${business?.id}`] ?? false ? '' : '#ffecf0', }}     >
                    Hours Of Operation
                    {businessHoursValidator[`${business?.id}`] ?? false ? ('') : (<span className='ml-3 has-text-danger'>   <small>Business Hours are required</small>  </span>)}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <HoursOperation
                    tab='LOCATION'
                    data={business ? business.businessHours : newDummySlots}
                    isLoading={isLoading}
                    udpateBusinessHoursStatus={udpateBusinessHoursStatus}
                    onSubmit={handleSubmit}
                    serviceId={business ? business.id : 0}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={4} dangerouslySetExpanded={addarr.includes(4)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 4 ? 1 : 0.5, pointerEvents: organization && activeStep >= 4 ? 'visible' : 'none', }}   >
                  <AccordionItemButton>Holidays Schedule</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <HolidaySchedule
                    tab='LOCATION'
                    data={{ ...business }}
                    refetch={refetch}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    organizationId={parseInt(organization) ?? -1}
                    gotToNextStep={gotToNextStep}
                    onChangeStep={(nextStep) => setActiveStep(nextStep)}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={5} dangerouslySetExpanded={addarr.includes(5)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 5 ? 1 : 0.5, pointerEvents: organization && activeStep >= 5 ? 'visible' : 'none', }}  >
                  <AccordionItemButton style={{ background: business?.services?.some(service => service?.isActive) ? '' : '#ffecf0' }}>
                    {/* <AccordionItemButton style={{ background: business?.services?.length === 0 ? '#ffecf0' : '', }}> */}
                    Services Setup
                    {business?.services?.length === 0 && (<span className='ml-3 has-text-danger'><small>Add atleast one service</small>  </span>)}
                    {business?.services?.length !== 0 && !business?.services?.some(service => service?.isActive) &&
                      (<span className='ml-3 has-text-danger'><small>Atleast one business service must be active</small>  </span>)}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ServicesSetup
                    tab='LOCATION'
                    data={{ ...business }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    infoMessage={data?.servicesSetup}
                  // infoMessage={data?.servicesSetup?.googleReserve}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={6} dangerouslySetExpanded={addarr.includes(6)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 6 ? 1 : 0.5, pointerEvents: organization && activeStep >= 6 ? 'visible' : 'none', }}  >
                  <AccordionItemButton style={{ background: business?.totalCapacity === 0 || (reserveValidation && !googleValidation) ? '#ffecf0' : '', }}  >
                    Customer Traffic Setup
                    {business?.totalCapacity === 0 && (<span className='ml-3 has-text-danger'> <small>Total capacity is required</small>  </span>)}
                    {reserveValidation && !googleValidation && (<span className='ml-3 has-text-danger'> <small>Enable User Appointment Scheduled and Business Appointment Scheduled </small>  </span>)}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <TrafficSetup
                    tab='LOCATION'
                    data={{ ...business }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={7} dangerouslySetExpanded={addarr.includes(7)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 7 ? 1 : 0.5, pointerEvents: organization && activeStep >= 7 ? 'visible' : 'none', }} >
                  <AccordionItemButton>
                    Appointment Confirmation Setup
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <AppointmentConfirmationSetup
                    tab='LOCATION'
                    data={{ ...business }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    toolTip={data?.appointmentConfirmationSetup}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={8} dangerouslySetExpanded={addarr.includes(8)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 8 ? 1 : 0.5, pointerEvents: organization && activeStep >= 8 ? 'visible' : 'none', }} >
                  <AccordionItemButton>Check-in Setup</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <CheckInSetup
                    tab='LOCATION'
                    data={{ ...business }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={9} dangerouslySetExpanded={genericNumberMessagingAllowed && addarr.includes(9)} onClick={() => !genericNumberMessagingAllowed && handleUpgrade()}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 9 ? 1 : 0.5, pointerEvents: organization && activeStep >= 9 ? 'visible' : 'none', }} >
                  <AccordionItemButton> {genericNumberMessagingAllowed ?
                    "Messaging Setup"
                    :
                    <> <span className=''>Messaging Setup</span> <Icon name='lock' className="is-size-5 has-text-danger mt-1 ml-3" />
                      <small className='has-text-danger ml-1'>This feature is not available with your subscription plan.</small> </>}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <CommunicationChecksSetup
                    tab='LOCATION'
                    data={{ ...business }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                    toolTip={data?.messagingSetup}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              {/* <AccordionItem uuid={10} dangerouslySetExpanded={addarr.includes(10)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 10 ? 1 : 0.5, pointerEvents: organization && activeStep >= 10 ? 'visible' : 'none', }}  >
                  <AccordionItemButton>Promotions Setup</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <PromotionSetup
                    tab='LOCATION'
                    data={{ ...business }}
                    refetch={refetch}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                  />
                </AccordionItemPanel>
              </AccordionItem> */}

              <AccordionItem uuid={11} dangerouslySetExpanded={addarr.includes(11)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 11 ? 1 : 0.5, pointerEvents: organization && activeStep >= 11 ? 'visible' : 'none', }}  >
                  <AccordionItemButton>
                    Communication Templates
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <CommunicationTemplates
                    data={{ ...business }}
                    refetch={refetch}
                  />
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid={12} dangerouslySetExpanded={addarr.includes(12)}>
                <AccordionItemHeading style={{ opacity: organization && activeStep >= 12 ? 1 : 0.5, pointerEvents: organization && activeStep >= 12 ? 'visible' : 'none', }}  >
                  <AccordionItemButton>GeoLocation Setup</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <GeoLocationSetup
                    tab='LOCATION'
                    data={{ ...business, countries, categories }}
                    isLoading={isLoading}
                    onSubmit={handleSubmit}
                  />
                </AccordionItemPanel>
              </AccordionItem>

            </Accordion>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Container >
  );
};
