import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import OneSignalReact from "react-onesignal"; // Import OneSignalReact SDK

const useNotificationListeners = (profile) => {
  const history = useHistory();

  // Extract profile.isLoggedIn to a variable
  const isLoggedIn = profile?.isLoggedIn;

  useEffect(() => {
    if (!isLoggedIn) return;

    const handleNotification = (notification) => {
      const { data } = notification;
      console.log("Notification data:", data);

      if (data?.notificationType === "BusinessMessage") {
        history.push(`/admin/messages/${data.businessId || ""}`);
      } else {
        history.push("/admin/businesses");
      }
    };
    
    try {
      console.log('Setting up notification listener...');
      OneSignalReact.addListenerForNotificationOpened((notification) => {
        handleNotification(notification);
      });
    } catch (error) {
      console.error('Error in Listener For Opened Notification OneSignalReact:', error);
    }
  }, [isLoggedIn, history]);
};

const useSyncUserProfileWithOneSignal = (profile) => {
  // Sync user profile with OneSignal
  useEffect(() => {
    const syncUserProfileWithOneSignal = async () => {
      if (!OneSignalReact) {
        console.warn("OneSignalReact SDK not loaded.");
        return;
      }

      try {
        const { profile: userProfile } = profile || {};
        const { sub } = userProfile || {};
        const externalUserId = await OneSignalReact.getExternalUserId();

        if (!sub && externalUserId) {
          console.log("No subscription detected, clearing OneSignal user data.");
          await OneSignalReact.logoutEmail();
          await OneSignalReact.removeExternalUserId();
        } else if (sub && externalUserId !== sub) {
          console.log(`Setting OneSignal externalUserId`);
          await OneSignalReact.setExternalUserId(sub);
        } else {
          console.log("OneSignal user already synced or no action needed.");
        }
      } catch (error) {
        console.error("Error syncing user profile with OneSignal:", error);
      }
    };

    if (profile?.isLoggedIn) {
      syncUserProfileWithOneSignal();
    }
  }, [profile]);
};

const NotificationHandler = ({ profile }) => {
  useNotificationListeners(profile);
  useSyncUserProfileWithOneSignal(profile);

  // This component is only used for its side effects
  return null;
};

export default NotificationHandler;

