import { prefixHolder } from 'services/prefix-holder';
const { Gateway, WebAdminFrontend } = prefixHolder;

export const oidcConfiguration = {
  authority: `${Gateway}`,
  client_id: 'safelyq.web',
  redirect_uri: `${WebAdminFrontend}/authentication/callback`,
  silent_redirect_uri: `${WebAdminFrontend}/assets/silent-callback.html`,
  post_logout_redirect_uri: `${WebAdminFrontend}/`,
  response_type: 'code',
  scope: 'openid profile email offline_access',
  automaticSilentRenew: true,
  loadUserInfo: true,
  // userStore: new WebStorageStateStore({ store: localStorage, key: 'authInfo' }), // Use 'authInfo' key
  // By default session storage is used
};