import { useLazyQuery } from '@apollo/client';
import { GET_APP_USERS, GET_CHATSLB_CONVERSATIONS, GET_CONVERSATION_MESSAGES } from './requests';
import { toast } from 'react-toastify';

export const useAppUsers = () => {
    const [getAppUsers, { loading, error, data }] = useLazyQuery(GET_APP_USERS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });

    return {
        loadingAppUsers: loading,
        error,
        appUsers: data ? data.getUsers : null,
        getAppUsers,
    };
};

export const useChatSLBConversations = () => {
    const [
        getChatSLBConversation,
        {
            loading: isLoadingConversation,
            error: isErrorConversation,
            data: conversationData,
            refetch: refetchAllConversations
        }
    ] = useLazyQuery(GET_CHATSLB_CONVERSATIONS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {

        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getChatSLBConversation,
        refetchAllConversations,
        isLoadingConversation,
        isErrorConversation,
        conversationData,
    };
};

export const useChatSLBConverastionMsgs = () => {
    const [
        getChatSLBConversationMsgs,
        {
            loading: isLoadingConversationMsgs,
            error: isErrorConversationMsgs,
            data: conversationMsgsData,
        }
    ] = useLazyQuery(GET_CONVERSATION_MESSAGES, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data?.getConversationMessages?.errorMessage) {
                toast.error(data?.getConversationMessages?.errorMessage);
            }
        },
        onError: (error) => {
            toast.error(error?.message || 'There was a problem. Data could not be loaded.');
        }
    });

    return {
        getChatSLBConversationMsgs,
        isLoadingConversationMsgs,
        isErrorConversationMsgs,
        conversationMsgsData,
    };
};