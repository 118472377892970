import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdminRoutes } from './AdminRoutes';
import { SuperAdminRoutes } from './SuperAdminRoutes';
import { useProfile } from 'hooks/useProfile';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';
import { useQuery } from '@apollo/client';
import Queries from 'components/Common/Queries';
import { useDispatch } from 'react-redux';
import { adduser } from 'Redux/AuthSlice/AuthSlice';
import NotificationHandler from './AdminRoutes/NotificationHandler';

const Routes = () => {
  const { loading, data } = useQuery(Queries.GET_USER_IS_PROFILE_COMPLETE);
  const profile = useProfile();
  const { login, oidcUser } = profile;

  // redirect to the login and than home if the user is already logged in
  const loggedInLocal = localStorage.getItem("authPersist");
  useEffect(() => {
    if (loggedInLocal === "true") {
      login();
    }
  }, [loggedInLocal, login])

  // update persist true
  useEffect(() => {
    if (oidcUser?.access_token) {
      localStorage.setItem("authPersist", "true")
    }
  }, [oidcUser]);

  const dispatch = useDispatch()
  if (data) {
    const userdata = {
      id: data.getUser.user?.id,
      email: data.getUser.user?.email,
      firstName: data.getUser.user?.firstName,
      lastName: data.getUser.user?.lastName,
      name: data.getUser.user?.name,
      isSuperAdmin: data.getUser.user?.isSuperAdmin,
      totalNotifications: data.isProfileComplete.unviewedCount,
      unreadCount: data.getUser.user?.conversationsSummary.unreadCount,
      // unreadCount: 0,
      isAuthenticated: data.isProfileComplete.isAuthenticated,
      isCompleted: data.isProfileComplete.isCompleted,
      isPhoneNumberConfirmed: data.isProfileComplete.isPhoneNumberConfirmed,
    }
    const obj = {
      authinfo: profile.oidcUser,
      userinfo: {
        ...userdata
      }
    }
    dispatch(adduser(obj));
  }


  return (
    <div>
      {loading && <FullPageLoader />}
      <NotificationHandler profile={profile} />
      <Switch>
        <Route path={'/superadmin'} component={() => <SuperAdminRoutes profile={profile} data={data} />} />
        <AdminRoutes profile={profile} />
      </Switch>
    </div>

  );
};

export default Routes;
