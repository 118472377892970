import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authinfo: null,
    userinfo: {
        isAuthenticated: false,
        unreadCount: 0,
        isPhoneNumberConfirmed: false,
        totalNotifications: 0,
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
        isCompleted: false,
        isSuperAdmin: false,
    }
}
export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        adduser: (state, action) => {
            state.authinfo = action.payload.authinfo
            state.userinfo.id = action.payload.userinfo.id
            state.userinfo.isAuthenticated = action.payload.userinfo.isAuthenticated
            state.userinfo.unreadCount = action.payload.userinfo.unreadCount
            state.userinfo.isPhoneNumberConfirmed = action.payload.userinfo.isPhoneNumberConfirmed
            state.userinfo.email = action.payload.userinfo.email
            state.userinfo.firstName = action.payload.userinfo.firstName
            state.userinfo.lastName = action.payload.userinfo.lastName
            state.userinfo.name = action.payload.userinfo.name
            state.userinfo.isSuperAdmin = action.payload.userinfo.isSuperAdmin
            state.userinfo.isCompleted = action.payload.userinfo.isCompleted
            state.userinfo.totalNotifications = action.payload.userinfo.totalNotifications
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    adduser
} = AuthSlice.actions

export default AuthSlice.reducer