import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Title, Button } from '@safelyq/bulma-ui-library';
import { APIPaths } from 'services/api-provider';

export const AdminLandingPage = (props) => {
  const { isLoggedIn } = props;
  const { push } = useHistory();
  if (isLoggedIn) push('/admin/businesses');

  return (
    <section className='hero is-medium'>
      <div className='hero-body is-flex-direction-column'>
        <img src='/images/triangle-logo.png' alt='logo' width={150} />
        <Title size='4'> Welcome to SafelyQ Admin</Title>
        <a href={`${APIPaths.WebFrontend}/for-business`} class='mb-5 has-text-link'>
          Learn More
        </a>
        <Link to='/admin/businesses'>
          <Button color='info' className='px-5'>
            Login to continue
          </Button>
        </Link>
      </div>
    </section>
  );
};
