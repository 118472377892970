import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { OidcSecure } from '@axa-fr/react-oidc-context';


export const ApolloRoute = ({ ...props }) => {
  const { component: Component, secure, isNavbarHide, profile, ...rest } = props;
  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) =>
          secure ? (
            <OidcSecure>
              <Component {...profile} {...props} />
            </OidcSecure>
          ) : (
            <Component {...profile} {...props} />
          )
        }
      />
    </Fragment>
  );
};
