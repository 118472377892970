import { Button, Section } from 'libraries/bulma-ui-library'
import React, { useEffect, useState } from 'react'
import { useConnectStripeHook } from './hooks'
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const StripeAccount = () => {
    const { id } = useParams();
    const [allowCustomerToPay, setAllowCustomerToPay] = useState(false);

    const [initialCustomerState, setInitialCustomerState] = useState(false);

    // Create an instance of URLSearchParams with the search part of the URL

    const [accountLinkState, setAccountLinkState] = useState(null);
    const {
        onGetStripeLink, isLoading, data,
        onAllowCustomerToPayFee,
        stripeData, getBusinessDetails
    } = useConnectStripeHook();
    const accountLink = data?.getBusinessStripeAccountLink?.accountLink;

    const allowCustomerFee = stripeData?.getBusinessById?.allowCustomerFee;
    const stripeAccountStatus = stripeData?.getBusinessById?.stripeAccountStatus;
    // const stripeAccountStatus = "NotStarted";

    useEffect(() => {
        if (allowCustomerFee) {
            setAllowCustomerToPay(allowCustomerFee)
            setInitialCustomerState(allowCustomerFee);
        }
    }, [allowCustomerFee]);

    useEffect(() => {
        getBusinessDetails({ variables: { id } })
    }, [])

    // set the account link to the state
    useEffect(() => {
        if (accountLink) {
            setAccountLinkState(accountLink)
        }
    }, [accountLink])

    const handlePermissionForCustomerToPay = () => {
        onAllowCustomerToPayFee({ variables: { businessId: id, allowCustomerFee: allowCustomerToPay } });
        setInitialCustomerState(allowCustomerToPay);
    }

    const handleConnectStripeAccount = () => {
        onGetStripeLink({ variables: { businessId: id } })
    }

    // This effect will run once when the component is mounted
    useEffect(() => {
        // Set a timeout remove the link after 5 minutes (300,000 ms)
        const timer = setTimeout(() => {
            setAccountLinkState(null);
        }, 300000);

        // Cleanup the timer on component unmount to avoid memory leaks
        return () => clearTimeout(timer);
    }, [accountLink]);

    return (
        <Section>
            <p className='is-size-5 has-font-black has-text-weight-semibold has-text-centered'> Stripe Account Integration </p>
            <div multiline className='columns is-flex is-flex-direction-column is-justify-content-center is-align-items-center'>


                <div className='column is-full-mobile is-half has-text-centered'>

                    {
                        stripeAccountStatus === "Active"
                            ?
                            <div>
                                <p className='is-size-6 has-font-black has-text-weight-normal'>
                                    {/* Click on the button to get your stripe account connected  */}
                                    Your stripe account is <span className='has-text-success has-text-weight-bold'>connected</span>.

                                </p>
                                <div className='is-flex is-justify-content-space-around mt-3'>
                                    <div className='is-flex'>
                                        <input type='checkbox' checked={!!allowCustomerToPay} // Ensure it's boolean
                                            onClick={() => setAllowCustomerToPay(!allowCustomerToPay)} />
                                        <p className='ml-2 mt-1'>Allow Customer To Pay Fee</p>
                                    </div>
                                    <Button
                                        color='info'
                                        onClick={() => handlePermissionForCustomerToPay()}
                                        className='is-small mx-3' disabled={initialCustomerState === allowCustomerToPay}>Update Permission</Button>
                                </div>

                                <hr />

                                <p className='is-size-6 has-font-black has-text-weight-normal'>
                                    Click on the button below to redirect to the stripe account to manage your details
                                </p>
                            </div>
                            :
                            stripeAccountStatus === "NotStarted"
                                ?
                                <p className='is-size-6 has-font-black has-text-weight-normal'>
                                    {/* Click on the button to get your stripe account connected  */}
                                    To start accepting payments, please connect your Stripe account by clicking the button below.
                                    This will securely link your account, allowing you to manage transactions and receive payments directly.
                                </p>
                                :
                                stripeAccountStatus === "UnderReview"
                                    ?
                                    <>
                                        <p className='is-size-6 has-font-black has-text-weight-normal'>
                                            {/* Click on the button to get your stripe account connected  */}
                                            Your stripe account is <span className='has-text-danger has-text-weight-bold'> Incomplete. </span>
                                        </p>
                                        <p className='is-size-6 has-font-black has-text-weight-normal'>
                                            Click on the button below to redirect to the stripe account and complete your account information
                                        </p>
                                    </>
                                    :
                                    ""
                    }

                </div>

                <div className='column is-full-mobile is-half has-text-centered'>
                    {/* <p className='is-size-5 has-font-black has-text-weight-semibold'> Stripe Account Integration </p> */}
                    <Button
                        disabled={accountLinkState}
                        className='mt-2'
                        color='info'
                        onClick={() => handleConnectStripeAccount()}
                    >
                        <span className='mx-3'> <span>{stripeAccountStatus === "NotStarted" ? "Connect" : "Stripe"}</span>  </span> {isLoading && <ClipLoader color='white' size='20' />}
                    </Button>
                    {
                        accountLinkState && <p
                            onClick={() => window.open(accountLinkState, '_blank')}
                            className='has-text-link has-text-weight-bold is-underlined is-clickable is-size-6 has-text-centered mt-4'>Redirect to stripe</p>}
                </div>
            </div>
        </Section>
    )
}

export default StripeAccount